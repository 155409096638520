import { ContentCardProps } from 'app/components/content/ContentCard'
import { Pagination } from 'app/types/pagination'
import { gql } from 'graphql-request'

import { defaultsQuery } from '../globals/defaults'

export const contentFragments = gql`
  fragment content on Content {
    id
    title
    slug

    thumbnail {
      id
      url
      sizes {
        thumbnail {
          url
        }
      }
    }
    commentCount
    disableComments
    createdAt
    publishedAt
    videoLength
    videoPost
    primaryCollection {
      name
      slug
    }

    contentTier {
      planLevel
    }

    content {
      ... on Poll {
        id
        poll {
          id
          questions {
            ... on SingleChoice {
              id
              question
              answers {
                id
                answer
                percent
                yourVote
              }
            }
          }
          closingDate
        }
        hideInFeatured
      }
    }
  }
`

export const createContentListQuery = () => gql`
  ${contentFragments}
  query getAllContent($limit: Int!, $page: Int!) {
    Contents(sort: "-publishedAt", limit: $limit, page: $page) {
      totalDocs
      page
      docs {
        ...content
      }
    }

    ${defaultsQuery}
  }
`

export const createContentSlugsOnlyQuery = () => gql`
  query getAllContent($limit: Int!, $page: Int!) {
    Contents(sort: "-publishedAt", limit: $limit, page: $page) {
      totalDocs
      page
      docs {
        slug
        updatedAt
      }
    }
  }
`

export const mapToContentCard = (
  data: any,
  defaults: any
): ContentCardProps => {
  return {
    id: data.id,
    image:
      data.thumbnail?.sizes?.thumbnail?.url ||
      data.thumbnail?.url ||
      defaults?.thumbnail?.sizes?.thumbnail?.url,
    contentTier: data.contentTier?.planLevel || 'initiate',
    title: data.title,
    href: `/content/${data.slug}`,
    slug: data.slug,
    categoryHref: `/collection/${data.primaryCollection?.slug}`,
    commentCount: data.commentCount,
    datePublished: new Date(
      data.publishedAt || data.createdAt
    ).toLocaleDateString(),
    category: data.primaryCollection?.name || '',
    duration: data.videoLength || '',
    videoPost: data.videoPost || false,
    poll: data.content?.find((item: any) => item.poll)?.poll || null,
    hidePoll:
      data.content?.find((item: any) => item.poll)?.hideInFeatured || false,
    disableComments: data?.disableComments || false,
  }
}

export const contentListQueryToContentList = (
  data: any
): Pagination<ContentCardProps> => {
  const res = data.Contents || data.search

  const docs = res.docs.map((item: any) =>
    mapToContentCard(item, data.Defaults)
  )

  return {
    total: res.totalDocs,
    page: res.page,
    docs,
  }
}

export const contentSlugsOnlyQueryToContentList = (
  data: any
): Pagination<{ slug: string; updatedAt: string }> => {
  const res = data.Contents || data.search

  const docs = res.docs.map((item: any) => ({
    slug: item.slug,
    updatedAt: item.updatedAt,
  }))

  return {
    total: res.totalDocs,
    page: res.page,
    docs,
  }
}
