import { isWeb, SizableText, XStack, YStack } from '@mythical/ui'
import { Button } from 'app/components/primitive/Button'
import Spinner from 'app/components/primitive/Spinner'
import Section, { SectionProps } from 'app/components/structure/Section'
import React from 'react'

import { ContentCardProps } from '../ContentCard'
import ContentCardGrid from './ContentCardGrid'
import ContentCardGridFlatList from './ContentCardGridFlatList'

interface IContentGridProps extends SectionProps {
  href?: string
  items: ContentCardProps[]
  hasMore?: boolean
  loadMore?: () => void
  loading?: boolean
  isFetchingNextPage?: boolean
  FlatListHeader?: React.ReactElement | null // Scrollable header for FlatList. Native only
  flatList?: boolean // Use FlatList to render grid. Native only
}

const LoadMoreButton = ({ loadMore, isFetchingNextPage }) => {
  return (
    <XStack
      jc="center"
      ai="center"
      mt="$3"
      $gtSm={{
        mt: '$4',
      }}
      $gtMd={{
        mt: '$5',
      }}
    >
      <Button onPress={loadMore} loading={isFetchingNextPage}>
        Load More
      </Button>
    </XStack>
  )
}

const ContentGrid = ({
  items,
  title,
  href,
  hasMore,
  loadMore,
  loading,
  isFetchingNextPage,
  FlatListHeader,
  flatList,
  ...props
}: IContentGridProps) => {
  const isFlatList = flatList && !isWeb
  const GridComp = isFlatList ? ContentCardGridFlatList : ContentCardGrid
  return (
    <Section
      padded
      title={title}
      {...(title && href && { ctaLabel: 'View All', ctaHref: href })}
      {...props}
      flexGrow={1}
    >
      <YStack flex={1}>
        {!loading && items?.length > 0 && (
          <GridComp
            items={items}
            flex={1}
            {...(isFlatList && {
              FlatListHeader,
              FlatListFooter: hasMore ? (
                <LoadMoreButton
                  loadMore={loadMore}
                  isFetchingNextPage={isFetchingNextPage}
                />
              ) : null,
            })}
          />
        )}
        {!isFlatList && hasMore && (
          <LoadMoreButton
            loadMore={loadMore}
            isFetchingNextPage={isFetchingNextPage}
          />
        )}
        {loading && <Spinner p="$4" size="large" />}
        {!loading && items?.length === 0 && (
          <SizableText
            textAlign="center"
            textTransform="uppercase"
            color="$secondary4"
          >
            No content found
          </SizableText>
        )}
      </YStack>
    </Section>
  )
}

export default ContentGrid
