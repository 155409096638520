import { YStack, styled, useMedia, XStack, GetProps } from '@mythical/ui'
import React from 'react'

import ContentCard from '../ContentCard'
import { ContentCardGridProps } from './ContentCardGridProps'

const ItemWrapper = styled(YStack, {
  p: '$2',
  width: '100%',
  $gtXs: {
    width: '50%',
  },
  $gtSm: {
    width: '33.333%',
  },
  $gtLg: {
    width: '25%',
  },
})

const GridWrapper = styled(XStack, {
  fd: 'column',
  m: '$-2',
  flex: 1,
  $gtXs: {
    fd: 'row',
    ai: 'flex-start',
    flexWrap: 'wrap',
    jc: 'flex-start',
  },
  $gtMd: {
    ai: 'flex-start',
  },
})

type GridWrapperProps = GetProps<typeof GridWrapper>

const ContentCardGrid = ({
  items,
  ...props
}: ContentCardGridProps & GridWrapperProps) => {
  const media = useMedia()
  return (
    <GridWrapper {...props}>
      {items.map((item) => (
        <ItemWrapper key={item.id}>
          <ContentCard {...item} size="sm" horizontal={media.xs} />
        </ItemWrapper>
      ))}
    </GridWrapper>
  )
}

export default GridWrapper.extractable(ContentCardGrid)
