import { Spinner as TGSpinner, styled } from '@mythical/ui'
import { primaryColors, secondaryColors } from '@mythical/ui-config/colors'

const Spinner = styled(TGSpinner, {
  color: primaryColors.primary10, // BUG: Can't use vars
  variants: {
    secondary: {
      true: {
        color: secondaryColors.secondary10,
      },
    },
  } as const,
})

export default Spinner
