import { Image, Stack, YStack, YStackProps } from '@mythical/ui'
import { Tag } from 'app/components/primitive/Tag/Tag'
import { IconPlay } from 'app/components/primitive/Icon'
import React from 'react'
import { timeToSeconds } from 'app/utils/time-to-seconds'

export interface ContentThumbnailProps {
  image: string
  duration?: string
  wrapperProps?: YStackProps
  iconSize?: number
  hidePlayButton?: boolean
  aspectRatio?: number
  secondsWatched?: number
}

const ContentThumbnail = ({
  wrapperProps,
  duration,
  image,
  iconSize = 50,
  hidePlayButton = false,
  aspectRatio = 16 / 9,
  secondsWatched,
}: ContentThumbnailProps) => {
  const videoWatchedPercentage =
    secondsWatched &&
    duration &&
    (secondsWatched / timeToSeconds(duration)) * 100

  return (
    <YStack
      w="100%"
      position="relative"
      borderRadius="$2"
      overflow="hidden"
      {...wrapperProps}
    >
      <YStack
        w="100%"
        scale={1}
        aspectRatio={aspectRatio}
        backgroundColor="$secondary9"
        hoverStyle={{
          scale: 1.2,
        }}
        animation={[
          'quick',
          {
            scale: {
              overshootClamping: true,
            },
          },
        ]}
      >
        <Image
          source={{ uri: image }}
          width="100%"
          height="100%"
          resizeMethod="scale"
          resizeMode="cover"
        />
      </YStack>
      {!hidePlayButton && (
        <Stack
          w="100%"
          jc="center"
          ai="center"
          h="100%"
          zIndex="$1"
          position="absolute"
          scale={1}
          className="pointer-events-none"
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
        >
          <IconPlay size={iconSize} />
        </Stack>
      )}

      {!!duration && (
        <Tag
          displaySize="sm"
          zIndex="$2"
          variant="neutral"
          position="absolute"
          bottom="$2"
          right="$2"
        >
          {duration}
        </Tag>
      )}

      {!!videoWatchedPercentage && (
        <Stack
          position="absolute"
          bottom={0}
          left={0}
          h={6}
          w="100%"
          bg="$secondary8"
          zIndex="$1"
        >
          <Stack
            position="absolute"
            bottom={0}
            left={0}
            h="100%"
            btrr="$1"
            bbrr="$1"
            w={`${videoWatchedPercentage}%`}
            bg="$primary10"
          />
        </Stack>
      )}
    </YStack>
  )
}

export default ContentThumbnail
