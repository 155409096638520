import {
  XStack,
  styled,
  GetProps,
  H2,
  SizableStack,
  SizableTextProps,
} from '@mythical/ui'
import { TextLink } from 'app/components/primitive/TextLink'
import React from 'react'

// For use in other components to match the padding of the section
export const SECTION_PADDING = {
  x: '$3',
  y: '$4',
  gtXs: {
    x: '$4',
    y: '$5',
  },
  gtLg: {
    x: '$6',
    y: '$6',
  },
}

// TODO:
// The padding variants can be way simpler, but there is a bug in Tamagui
// Where properties in media queries are not being merged correctly on native
const SectionWrapper = styled(SizableStack, {
  name: 'Section',
  tag: 'section',
  flexDirection: 'column',
  width: '100%',
  gap: '$3',
  bg: '$secondary10',
  $gtXs: {
    gap: '$5',
  },
  variants: {
    padded: {
      true: {
        px: SECTION_PADDING.x,
        py: SECTION_PADDING.y,
        $gtXs: {
          px: SECTION_PADDING.gtXs.x,
          py: SECTION_PADDING.gtXs.y,
        },
        $gtLg: {
          px: SECTION_PADDING.gtLg.x,
          py: SECTION_PADDING.gtLg.y,
        },
      },
    },
    paddedX: {
      true: {
        px: SECTION_PADDING.x,
        $gtXs: {
          px: SECTION_PADDING.gtXs.x,
        },
        $gtLg: {
          px: SECTION_PADDING.gtLg.x,
        },
      },
    },
    paddedY: {
      true: {
        py: SECTION_PADDING.y,
        $gtXs: {
          py: SECTION_PADDING.gtXs.y,
        },
        $gtLg: {
          py: SECTION_PADDING.gtLg.y,
        },
      },
    },
    paddedTop: {
      true: {
        pt: SECTION_PADDING.y,
        $gtXs: {
          pt: SECTION_PADDING.gtXs.y,
        },
        $gtLg: {
          pt: SECTION_PADDING.gtLg.y,
        },
      },
    },
    paddedBottom: {
      true: {
        pb: SECTION_PADDING.y,
        $gtXs: {
          pb: SECTION_PADDING.gtXs.y,
        },
        $gtLg: {
          pb: SECTION_PADDING.gtLg.y,
        },
      },
    },
    spaciousY: {
      true: {
        py: '$6',
        $gtXs: {
          py: '$8',
        },
        $gtLg: {
          py: '$10',
        },
      },
    },
    spaciousBottom: {
      true: {
        pb: '$6',
        $gtXs: {
          pb: '$8',
        },
        $gtLg: {
          pb: '$10',
        },
      },
    },
  } as const,
})

export type SectionWrapperProps = GetProps<typeof SectionWrapper>

const HeadingWrapper = styled(XStack, {
  flexDirection: 'row',
  w: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '$2',
  variants: {
    centered: {
      true: {
        justifyContent: 'center',
        $gtSm: {
          justifyContent: 'space-between',
        },
      },
    },
  } as const,
})

export interface SectionProps extends SectionWrapperProps {
  title?: string | React.ReactNode
  titleHighlight?: string
  titleProps?: SizableTextProps
  ctaLabel?: string
  ctaHref?: string
  children?: React.ReactNode
  centered?: boolean
  customCta?: React.ReactNode
}

const Section = ({
  title,
  titleHighlight,
  centered,
  ctaHref,
  ctaLabel,
  children,
  customCta,
  titleProps,
  ...props
}: SectionProps) => {
  const renderHeader =
    title || titleHighlight || customCta || (ctaHref && ctaLabel)
  return (
    <SectionWrapper {...props}>
      {renderHeader && (
        <HeadingWrapper centered={centered}>
          {title && (
            <H2
              fontSize="$7"
              lineHeight="$8"
              textTransform="uppercase"
              fontWeight="500"
              $gtSm={{
                fontSize: '$8',
              }}
              color="$primary10"
              {...titleProps}
            >
              {title}{' '}
              {titleHighlight && (
                <H2
                  tag="span"
                  fontSize="$7"
                  lineHeight="$8"
                  $gtSm={{
                    fontSize: '$8',
                  }}
                  color="$primary1"
                  textTransform="uppercase"
                >
                  {titleHighlight}
                </H2>
              )}
            </H2>
          )}
          {!customCta && ctaLabel && ctaHref && (
            <TextLink
              href={ctaHref}
              color="$primary6"
              fontSize="$2"
              textTransform="uppercase"
              px="$1"
              py="$2"
              hoverStyle={{
                color: '$primary10',
              }}
              pressStyle={{
                color: '$primary10',
              }}
              focusStyle={{
                color: '$primary10',
              }}
            >
              {ctaLabel}
            </TextLink>
          )}
          {customCta && customCta}
        </HeadingWrapper>
      )}

      {children}
    </SectionWrapper>
  )
}

export default Section
