import React, { memo } from 'react'
import { Path, Circle, G, ClipPath, Defs } from 'react-native-svg'

import { IconProps } from '../IconProps'
import { IconBase } from '../IconBase'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Circle opacity={0.8} cx={22} cy={22} r={22} fill="#2A3314" />
      <G clipPath="url(#a)">
        <Path
          d="M18.333 15.583v12.834L28.417 22l-10.084-6.417Z"
          fill="#E2CD9E"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" transform="translate(11 11)" d="M0 0h22v22H0z" />
        </ClipPath>
      </Defs>
    </IconBase>
  )
}

Icon.displayName = 'IconPlay'

export const IconPlay = memo<IconProps>(themed(Icon))
