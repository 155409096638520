/**
 * Converts a time string in the format "hh:mm:ss" to seconds
 */
export const timeToSeconds = (timeStr: string): number => {
  const timeParts = timeStr.split(":").map(Number);
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (timeParts.length >= 1) {
    seconds = timeParts[timeParts.length - 1] || 0;
  }

  if (timeParts.length >= 2) {
    minutes = timeParts[timeParts.length - 2] || 0;
  }

  if (timeParts.length >= 3) {
    hours = timeParts[timeParts.length - 3] || 0;
  }

  const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
  return totalSeconds;
}