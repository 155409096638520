import { isWeb } from '@mythical/ui'
import useAnalytics from 'app/lib/analytics'
import { CollectionType } from 'app/queries/collections/collection-fragment'
import { NextSeo } from 'next-seo'
import React, { useEffect } from 'react'

type Type = CollectionType | 'content' | 'pages' | 'collectible'

interface ContentMetaProps {
  title?: string
  type?: Type
  slug?: string
  description?: string
  image?: string
}

function getPath(type?: Type, slug?: string) {
  if (!type && !slug) return '/'

  if (!type) return `/${slug}`

  if (type === 'MemberTier') return `/tier/${slug}`

  return `/${type.toLowerCase()}/${slug}`
}

export const Meta = ({
  title,
  type,
  slug,
  description,
  image,
}: ContentMetaProps) => {
  const analytics = useAnalytics()

  useEffect(() => {
    if (!analytics) return

    if (isWeb) {
      analytics.logEvent('page_view', {
        page_title: `${title} | Mythical Society`,
        page_path: getPath(type, slug),
      })
    } else {
      analytics.logScreenView(
        `${title} | Mythical Society`,
        getPath(type, slug)
      )
    }
  }, [title, type, slug, analytics])

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        title,
        description,
        images: image
          ? [
              {
                url: image,
                width: 600,
                height: 338,
              },
            ]
          : undefined,
      }}
    />
  )
}
